.subscriptionContainer {
    height: 0;
    overflow: hidden;
    width: 100%;
    border-radius: 23px;
    bottom: 0;
}

.subscriptionContainer.top {
    bottom: unset;
    top: -300px;
}

.subscriptionContainer[data-isopen="true"] {
    height: auto;
    padding: 25px 30px 25px 30px;
}

.subscriptionContainer > * {
    display: none;
}

.subscriptionContainer[data-isopen="true"] > * {
    display: unset;
}