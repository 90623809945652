.container {
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: center;

    background-color: var(--lightBlue);
    min-height: 100vh;

    padding: 80px 0 240px 0;

    gap: 32px;
}

.progressContainer {
    width: min(868px, calc(100vw - 32px));

    /* Small devices (portrait tablets and large phones, 768px and down) */
    @media only screen and (max-width: 639px) {
        width: calc(100vw - 32px);
    }
    @media only screen and (min-width: 640px) {
        width: min(868px, calc(85vw - 32px));
    }
}

.progressSteps {
    display: flex;
    flex-direction: row;

    justify-content: space-around;
    align-items: center;
}

.progressSteps > * {
    font-family: Graphik, serif;
    width: 20%;
    font-size: 13px;
    text-align: center;
    user-select: none;
    cursor: pointer;
    margin: 8px;
    overflow: clip;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media only screen and (max-width: 639px) {
        font-size: 11px;
    }
}

.progressSteps .desktopText {
    display: none;

    @media only screen and (min-width: 640px) {
        display: unset;
    }
}

.progressSteps .mobileText {
    @media only screen and (min-width: 640px) {
        display: none;
    }
}

.progressSteps > .active {
    font-weight: 500;
}

.progressContainer > .rail {
    width: 100%;
    height: 8px;


    @media only screen and (max-width: 639px) {
        height: 5px;
    }

    @media only screen and (min-width: 640px) {
        height: 8px;
    }

    border-radius: 4px;

    background-color: var(--blue);
}

.rail > .progress {
    width: 0;
    height: 100%;
    border-radius: 4px;

    background-color: var(--darkBlue);

    transition: width 500ms ease-out;
}

.flexRow {
    display: flex;
    flex-direction: row;

    gap: var(--gap);
    row-gap: var(--gap);

    justify-content: flex-start;
    align-items: center;
}

.flexColumn {
    display: flex;
    flex-direction: column;
}

.flexMainStart {
    justify-content: start;
}

.flexMainCenter {
    justify-content: center;
}

.flexMainEnd {
    justify-content: flex-end;
}

.flexMainSpaceBetween {
    justify-content: space-between;
}

.flexMainSpaceAround {
    justify-content: space-around;
}

.flexMainSpaceEvenly {
    justify-content: space-evenly;
}

.flexCrossStart {
    align-items: flex-start;
}

.flexCrossCenter {
    align-items: center;
}

.flexCrossStretch {
    align-items: stretch;
}

.flexCrossEnd {
    align-items: flex-end;
}

.flexWrap {
    display: flex;
    flex-wrap: wrap;
}

.cardWrapper {
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: center;

    gap: var(--gap);
    row-gap: var(--gap);

    width: min(900px, 100%);

    /* Small devices (portrait tablets and large phones, 768px and down) */
    @media only screen and (max-width: 639px) {
        width: 100%;
    }
    @media only screen and (min-width: 640px) {
        width: min(900px, 85vw);
    }
}

.card {
    display: flex;
    flex-direction: column;
    padding: var(--gap);

    width: 100%;

    background-color: var(--lightBlue);
    border-radius: 20px;
    box-shadow: 0 6px 10px 1px rgba(0, 0, 0, var(--shadow-key-penumbra-opacity)),
    0 1px 14px 2px rgba(0, 0, 0, var(--shadow-ambient-shadow-opacity)),
    0 3px 5px -3px rgba(0, 0, 0, var(--shadow-key-umbra-opacity));

    font-family: Graphik, serif;
    font-size: 15px;

    gap: var(--sectionGap);
}

p.orderSectionContent {
    max-width: 720px;
    margin-bottom: 28px;
}

div.orderSectionContent > p, ul.orderSectionContent {
    max-width: 720px;
    margin-bottom: 12px;
}

ul.orderSectionContent > li {
    margin-bottom: 8px;
}

.limitedWidth {
    max-width: 720px;
}

.cardOption {
    display: flex;
    justify-content: center;
    align-items: center;

    aspect-ratio: 4/1;

    width: max(min(100%, 400px), calc((100% - var(--gap) * 2) / 3));

    @media only screen and (max-width: 639px) {
        width: 100%;
        aspect-ratio: 6/1;
    }

    @media only screen and (min-width: 640px) {
        width: calc((100% - var(--gap) * 2) / 3);
    }

    background-color: var(--lighterBlue);
    border-radius: 10px;
    padding: 8px 12px 8px 12px;
    min-height: 72px;

    cursor: pointer;

    font-family: Graphik, serif;
    font-size: 15px;
}

.cardOption:hover {
    opacity: 85%;
}

.cardOption.selected {
    border: 1px solid var(--darkBlue);
    font-weight: 500;
}

.cardPaymentOption {
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 639px) {
        width: 100%;
        aspect-ratio: 6/1;
    }

    @media only screen and (min-width: 640px) {
        width: calc((100% - var(--gap)) / 2);
    }

    background-color: var(--lighterBlue);
    border-radius: 10px;
    padding: 8px 12px 8px 12px;
    aspect-ratio: 3/1;
    min-height: 72px;

    cursor: pointer;

    font-family: Graphik, serif;
    font-size: 15px;
}

.cardPaymentOption:hover {
    opacity: 85%;
}

.cardPaymentOption.selected {
    border: 1px solid var(--darkBlue);
    font-weight: 500;
}

.cardPaymentOption img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.cardPaymentOption.mobilepay img {
    height: 55%;
}

.cardH1 {
    font-family: "Cooper Lt BT", serif;
    font-size: 30px;
    line-height: 1.2;
    margin-bottom: var(--gap);
}

.cardH2 {
    font-family: "Cooper Lt BT", serif;
    font-size: 24px;
    line-height: 1.2;
    margin-bottom: var(--gap);
}

.cardH3 {
    font-family: "Cooper Lt BT", serif;
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: var(--gap);
}

.removeTopMargin {
    margin-top: calc(var(--gap) * -0.8);
}

.extraServiceOption {
    width: calc((100% - var(--gap)) / 2);
    min-height: 140px;
    overflow: clip;

    /* Small devices (portrait tablets and large phones, 600px and up) */
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 600px) and (max-width: 991px) {
        width: calc((100% - var(--gap) * 2) / 3) !important;
        overflow: clip;
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        width: calc((100% - var(--gap) * 3) / 4) !important;
        overflow: clip;
    }

    display: flex;
    flex-direction: column;

    background-color: var(--lighterBlue);
    border-radius: 10px;
    padding: 8px;

    font-family: Graphik, serif;
    font-size: 14px;
    text-align: center;

    cursor: pointer;

    border: 2px solid transparent;
}

.windowOption {
    width: calc((100% - var(--gap)) / 2);
    min-height: 140px;
    max-height: 188px;
    overflow: clip;

    /* Small devices (portrait tablets and large phones, 600px and up) */
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 600px) and (max-width: 991px) {
        width: calc((100% - var(--gap) * 2) / 3) !important;
        overflow: clip;
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        width: calc((100% - var(--gap) * 3) / 4) !important;
        overflow: clip;
    }

    display: flex;
    flex-direction: column;

    background-color: var(--lighterBlue);
    border-radius: 10px;
    padding: 8px;

    font-family: Graphik, serif;
    font-size: 14px;
    text-align: center;

    border: 2px solid transparent;
}

.extraServiceOption.shimmer, .windowOption.shimmer {
    background: var(--lighterBlue) linear-gradient(to right, var(--lighterBlue) 0%, var(--lightBlue) 20%, var(--lighterBlue) 40%, var(--lighterBlue) 100%) no-repeat;
    background-size: 1200px 188px;
    display: inline-block;
    position: relative;

    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: shimmer;
    -webkit-animation-timing-function: linear;
}

.extraServiceOption.selected {
    border: 1px solid var(--darkBlue);
}

.windowOption.selected {
    border: 2px solid var(--darkBlue);
}

.extraServiceOption > .extraServiceName, .windowOption > .windowName {
    display: block;
    min-height: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-bottom: 4px;
}

.extraServiceOption > .extraServiceName {
    padding-top: 12px;
}

.extraServiceOption > .extraServicePrice {
    display: block;
    min-height: 24px;
    text-overflow: ellipsis;
    padding-bottom: 4px;
}

.extraServiceOption.selected > .extraServiceName, .windowOption.selected > .windowName {
    font-weight: 450;
}

textarea.textField {
    display: block;
    border-radius: 10px;

    padding: 8px 12px 8px 12px;
    margin-top: 8px;
    margin-bottom: 12px;
    width: 100%;
    min-height: 80px;
    color: var(--darkBlue);
    background-color: var(--lighterBlue);
    text-align: start;
}

textarea.textField:focus {
    outline: none;
}

input.inputField {
    border-radius: 10px;
    background-color: var(--lighterBlue);
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 8px;
    margin-bottom: 12px;
    height: 38px;
    text-align: center;
    width: 100%;
}

.modalContainer input, .modalContainer select {
    border-radius: 10px;
    background-color: var(--lighterBlue);
    width: 100%;
    margin-top: 8px;
    margin-bottom: 12px;
    text-align: center;
    height: 38px;
    padding: 6px 13px;
    font-family: Graphik, sans-serif;
    font-size: 15px;
    color: #2A4D4F;
}

.modalContainer select {
    margin-bottom: 16px;
}

.modalContainer select:focus {
    outline: none;
}

.windowOption input {
    border-radius: 12px;
    background-color: var(--lightBlue);
    padding-left: 10px;
    padding-right: 10px;
    height: 24px;
    max-width: 42px;
    text-align: center;
}

.extraServiceModal input::placeholder, .windowOption input::placeholder, .orderSection input::placeholder, .textField::placeholder {
    color: var(--blue);
}

.extraServiceModal input:focus, .windowOption input:focus, .orderSection input:focus {
    outline: none;
}

.extraServiceModal input, .extraServiceModal label, .extraServiceModal select {
    display: block;
    font-weight: 500;
}

.extraServiceModal input, .extraServiceModal label, .extraServiceModal select {
    display: block;
    font-weight: 500;
}

.extraServiceModal .extraServiceCountButton {
    border-radius: 100%;
    cursor: pointer;

    position: absolute;

    width: 24px;
    height: 24px;

    background: var(--darkBlue);
    color: var(--lighterBlue);
    text-align: center;
    user-select: none;

    margin: 8px 4px 8px 4px;
}

.extraServiceModal label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    font-weight: 500;
}

.extraServiceModal label * {
    font-weight: normal;
}

.extraServiceImage {
    display: flex;
    flex: auto;
    overflow: hidden;
    padding: 8px;
    border-radius: 50%;
    aspect-ratio: 1;
    object-fit: cover;
}

.windowImage {
    display: flex;
    flex: auto;
    overflow: hidden;
}

.windowOptionFooter {
    display: flex;
    flex: auto;
    flex-direction: row;

    justify-content: center;
    align-items: center;
    padding-bottom: 4px;
    gap: 8px;
}

.windowOptionFooter > div {
    border-radius: 12px;
    background-color: var(--lightBlue);
    width: 24px;
    height: 24px;
    font-size: 15px;
    text-align: center;
    user-select: none;
}

.windowOptionButton {
    border-radius: 10px;
    cursor: pointer;
}

.calendarWrapper {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12px;
    border-radius: 10px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, var(--shadow-ambient-shadow-opacity));
    background-color: var(--lighterBlue);
}

.orderHandler {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-bottom: 12px;
    border-radius: 10px;
    background-color: var(--lighterBlue);
    padding: 12px;

    @media only screen and (max-width: 600px) {
        width: 100%;
        min-height: 220px;
    }

    min-height: 320px;
}

.overviewCard {
    width: 100%;

    @media only screen and (min-width: 600px) {
        width: calc((100% - var(--gap) * 2) / 3);
    }
}

.overviewCard > h2 {
    font-size: 12px;
}

.thirdAspectRatio {
    aspect-ratio: 3/1;
}

.half {
    width: calc((100% - var(--gap)) / 2);
}

.desktopHalf {
    width: 100%;

    @media only screen and (min-width: 600px) {
        width: calc((100% - var(--gap)) / 2);
    }
}

.modalContainer {
    display: flex;
    position: fixed;
    z-index: 40;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;

    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.4);

    padding: max(64px, 10vh) 0 15vh 0;
}

.modalCard {
    position: relative;
    min-height: min(200px, 50vh);

    padding: 24px;

    width: min(720px, 85vw);

    @media only screen and (max-width: 639px) {
        width: 85%;
    }

    @media only screen and (min-width: 640px) {
        width: min(720px, 80vw);
    }

    background-color: var(--lightBlue);
    border-radius: 20px;
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, var(--shadow-key-penumbra-opacity)),
    0 3px 14px 2px rgba(0, 0, 0, var(--shadow-ambient-shadow-opacity)),
    0 5px 5px -3px rgba(0, 0, 0, var(--shadow-key-umbra-opacity));

    font-family: Graphik, serif;
    font-size: 15px;

    overflow-y: auto;
}

.closeButton {
    cursor: pointer;
    position: absolute;
    color: white;
    top: 16px;
    right: 12px;
    overflow: hidden;
    width: 24px;
    height: 24px;
    padding: 4px;
}

.closeButton > svg {
    fill: var(--darkBlue);
    width: 100%;
    height: 100%;
}

.closeButton > svg * {
    width: 100%;
    height: 100%;
}

.buttonWrapper {
    text-transform: uppercase;
    text-align: center;
    user-select: none;
    margin: 8px;

    @media only screen and (max-width: 639px) {
        max-width: 40vw;
    }
}

.buttonWrapper > button {
    border-radius: 10px;
    background-color: var(--darkBlue);
    color: var(--lighterBlue);
    font-family: Graphik, serif;
    font-size: 15px;
    padding: 10px;
    width: 100%;
}

.buttonWrapper.disabled > button, .buttonWrapper > button:disabled {
    background-color: var(--blue);
    color: var(--lightBlue);
    cursor: unset;
}

.buttonWrapper.outline > button {
    background-color: unset;
    border: var(--darkBlue) 2px solid;
    color: var(--darkBlue);
    font-family: Graphik, serif;
    font-size: 15px;
}

.buttonWrapper.outline:not(.disabled) > button:hover {
    opacity: 60%;
}

.buttonWrapper.outline.disabled > button {
    background-color: unset;
    border: var(--blue) 2px solid;
    color: var(--blue);
}

.hidden {
    display: none !important;
}

.addressCard {
    display: flex;
    justify-content: center;
    align-items: center;


    background-color: var(--lighterBlue);
    border-radius: 10px;
    padding: 8px 12px 8px 12px;
    min-height: 72px;

    cursor: pointer;

    font-family: Graphik, serif;
    font-size: 15px;
}

.addressCard:hover {
    opacity: 85%;
}

a.hyperlink {
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 2px;
    text-decoration: underline;
}

.priceFooter {
    display: flex;
    flex-direction: column;
    left: 50%;
    transform: translateX(-50%);
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px 16px 20px;
    border-radius: 10px 10px 0 0;
    z-index: 51;

    background-color: var(--lighterBlue);
    width: clamp(min(320px, 100vw), 90vw, 640px);
    height: 84px;

    font-family: 'Graphik', serif;
    font-size: 13px; /* fallback */
    font-size: clamp(13px, 4vw, 15px);

    position: fixed;
    bottom: 0;

    box-shadow: 0 6px 10px 1px rgba(0, 0, 0, var(--shadow-key-penumbra-opacity)),
    0 1px 14px 2px rgba(0, 0, 0, var(--shadow-ambient-shadow-opacity)),
    0 3px 5px -3px rgba(0, 0, 0, var(--shadow-key-umbra-opacity));
}

.footerContent {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.priceDetails {
    position: fixed;
    bottom: 72px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 51;

    background-color: var(--lighterBlue);
    border-radius: 10px 10px 0 0;
    max-height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0 6px 10px 1px rgba(0, 0, 0, var(--shadow-key-penumbra-opacity)),
    0 1px 14px 2px rgba(0, 0, 0, var(--shadow-ambient-shadow-opacity)),
    0 3px 5px -3px rgba(0, 0, 0, var(--shadow-key-umbra-opacity));

    font-family: 'Graphik', serif;
    font-size: 13px; /* fallback */
    font-size: clamp(13px, 4vw, 15px);

    padding: 20px;
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 8px;

    width: clamp(min(304px, 90vw), 85vw, 624px);
}

.priceDetails::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.priceDivider {
    display: flex;
    min-height: 1px;
    margin: 8px;
    align-self: stretch;

    background-color: var(--darkBlue);
}

.priceRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.priceDetails.expanded {
    display: flex;
}

.priceFooter > .button {
    cursor: pointer;
    padding: 4px;
    text-align: center;
    gap: 12px;
    white-space: nowrap;
}

.priceFooter > .button:hover {
    opacity: 85%;
}

.priceFooter .header {
    font-weight: 500;
    font-family: "Cooper Lt BT", serif;
    font-size: 20px;
    margin-top: -4px;
    margin-bottom: -4px;
}

.priceDetails .header {
    font-weight: 500;
    font-family: "Cooper Lt BT", serif;
    font-size: 20px;
    margin-top: -4px;
    margin-bottom: -4px;
    align-self: center;
}

@keyframes shimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    border: 3px solid var(--lightBlue); /* Light grey */
    border-top: 4px solid var(--darkBlue); /* Blue */
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin: auto;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 84px;
}
