@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Cooper Lt BT';
    src: url('/fonts/font.woff');
    src: url('/fonts/font.woff2');
    font-style: normal;
    font-weight: 300;
    font-display: fallback;
}

@font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik-Regular-Web.woff');
    src: url('/fonts/Graphik-Regular-Web.woff2');
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
}

@font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik-Semibold-Web.woff');
    src: url('/fonts/Graphik-Semibold-Web.woff2');
    font-style: normal;
    font-weight: 600;
    font-display: fallback;
}

@font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik-Medium-Web.woff');
    src: url('/fonts/Graphik-Medium-Web.woff2');
    font-style: normal;
    font-weight: 500;
    font-display: fallback;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #B2D6D9;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #2A4D4F;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(42, 77, 79, 0.89)
}

/* Chrome, Safari, Edge, Opera */
.no-arrow-spin::-webkit-outer-spin-button,
.no-arrow-spin::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.no-arrow-spin[type=number] {
    -moz-appearance: textfield;
}

body {
    scrollbar-width: none;
}

.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
    --darkBlue: #2A4D4F;
    --blue: #6D9093;
    --lightBlue: #97C4C9;
    --lighterBlue: #B2D6D9;
    --lighterBlueAlt: #B1D3D7;
    --lightestBlue: #E1F4F6;
    --todayColor: #ECFDFF;

    --darkGreen: #104925;
    --green: #679370;
    --lightGreen: #BFDDBB;
    --lighterGreen: #CFE5CC;

    --brown: #4C402A;
    --lightBrown: #B6A26D;
    --sand: #E7CF8C;
    --lightSand: #EDDBA8;

    --red: #CC3E0C;

    --gap: 24px;
    --sectionGap: 40px;

    --shadow-key-umbra-opacity: 0.20;
    --shadow-key-penumbra-opacity: 0.14;
    --shadow-ambient-shadow-opacity: 0.12;

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        --gap: 24px;
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        --gap: 24px;
    }

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        --gap: 24px;
    }

    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        --gap: 32px;
    }
}
